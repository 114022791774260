import { useQuery } from '@tanstack/react-query'
import { getCredentialsForVerification } from '../../api/credentials/credential_actions'

export const useVerifyCredentialsQuery = (daysLookahead: number) => {
  return useQuery({
    queryKey: ['verifyCredentials', daysLookahead],
    queryFn: () => {
      return getCredentialsForVerification(daysLookahead)
    },
  })
}
