import { useState, useEffect } from 'react'
import { CredentialCheckResponse } from '../../types/credentials'
import graphql from '../../graphql'
import { shiftsQuery } from '../../components/Shifts/shifts'

export const useShiftDetails = (
  credentialChecks: CredentialCheckResponse[] | undefined
) => {
  const [shiftDetails, setShiftDetails] = useState<Record<number, any>>({})

  useEffect(() => {
    const loadShiftDetails = async () => {
      if (!credentialChecks) return

      const shiftPromises = credentialChecks.map(async (check) => {
        try {
          const response = await graphql(
            shiftsQuery({ shiftId: check.shift.id })
          )
          return {
            id: check.shift.id,
            data: response.data.data.admin.shifts[0],
          }
        } catch (error) {
          return null
        }
      })

      const results = await Promise.all(shiftPromises)
      const newShiftDetails: Record<number, any> = {}
      results.forEach((result) => {
        if (result) {
          newShiftDetails[result.id] = result.data
        }
      })
      setShiftDetails(newShiftDetails)
    }

    loadShiftDetails()
  }, [credentialChecks])

  return shiftDetails
}
