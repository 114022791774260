import React from 'react'
import { Modal, Heading, Text, Box, Button } from '@workwhile/ui'

type ConfirmationModalProps = {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  action: 'approve' | 'reject'
  isProcessing: boolean
}

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  action,
  isProcessing,
}) => (
  <Modal open={isOpen} onClose={onClose}>
    <Heading variant="h2">Confirm Action</Heading>
    <Text>Are you sure you want to {action} this worker's certification?</Text>
    <Box display="flex" marginTop="16px">
      <Button
        variant="primary"
        onClick={onConfirm}
        disabled={isProcessing}
        marginRight="8px"
      >
        {isProcessing ? 'Processing...' : 'Confirm'}
      </Button>
      <Button variant="secondary" onClick={onClose} disabled={isProcessing}>
        Cancel
      </Button>
    </Box>
  </Modal>
)
