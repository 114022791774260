import React from 'react'
import { Box, Text, Button } from '@workwhile/ui'
import { Worker, WorkerCredential } from '../../../types/credentials'

type WorkerCardProps = {
  worker: Worker
  credential: WorkerCredential
  onApprove: () => void
  onReject: () => void
}

export const WorkerCard: React.FC<WorkerCardProps> = ({
  worker,
  credential,
  onApprove,
  onReject,
}) => (
  <Box flex="1" marginRight="32px">
    <Box
      display="flex"
      marginBottom="24px"
      flexDirection={['column', 'column', 'row']}
    >
      {/* Profile Picture */}
      <Box
        width={['200px', '200px', '200px']}
        height={['200px', '200px', '200px']}
        flexShrink={0}
        borderRadius="8px"
        overflow="hidden"
        marginRight={[0, 0, '16px']}
        marginBottom={['16px', '16px', 0]}
      >
        {worker.profilePicture ? (
          <img
            src={worker.profilePicture}
            alt={`Profile for ${worker.name}`}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
        ) : null}
      </Box>

      {/* Worker Info */}
      <Box flex="1">
        <Box
          display="flex"
          marginBottom="16px"
          flexDirection={['column', 'column', 'row']}
          alignItems={['flex-start', 'flex-start', 'center']}
        >
          <Text
            fontWeight="bold"
            fontSize="lg"
            marginRight={[0, 0, '8px']}
            marginBottom={['8px', '8px', 0]}
          >
            {worker.name || 'Unknown'}
          </Text>
          <Text color="gray.600">#{worker.id}</Text>
        </Box>
        <Text color="gray.600">
          {credential.requirementName || 'No requirement specified'}
        </Text>
      </Box>
    </Box>

    {/* Action Buttons */}
    <Box display="flex">
      <Button variant="primary" onClick={onApprove} marginRight="8px">
        Approve
      </Button>
      <Button variant="secondary" onClick={onReject}>
        Reject
      </Button>
    </Box>
  </Box>
)
