import React from 'react'
import { Box, Text } from '@workwhile/ui'
import { useDocumentPreview } from '../DocumentPreviewContext'

type DocumentPreviewProps = {
  urls: string[]
}

export const DocumentPreview: React.FC<DocumentPreviewProps> = ({ urls }) => {
  const { setSelectedUrl } = useDocumentPreview()

  return (
    <Box>
      {urls?.map((url, urlIndex) => (
        <Box key={urlIndex}>
          <Box
            backgroundColor="white"
            borderRadius="8px"
            overflow="hidden"
            border="1px solid"
            borderColor="gray.200"
            marginBottom="8px"
          >
            {url.match(/\.(jpg|jpeg|png|gif|webp)$/i) ? (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                backgroundColor="pink.100"
                padding="16px"
              >
                <img
                  src={url}
                  style={{
                    width: '500px',
                    height: '300px',
                    objectFit: 'contain',
                  }}
                  alt="Credential document"
                />
              </Box>
            ) : (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                backgroundColor="pink.100"
                padding="16px"
              >
                <iframe
                  src={`${url}#view=FitW&toolbar=0&navpanes=0`}
                  style={{
                    width: '500px',
                    height: '300px',
                    border: 'none',
                    margin: '0 auto',
                    display: 'block',
                  }}
                  frameBorder="0"
                  title="Credential document"
                />
              </Box>
            )}
          </Box>
          <Box
            textAlign="center"
            marginBottom={urlIndex < urls.length - 1 ? '16px' : '0'}
          >
            <Text
              as="a"
              color="blue.500"
              cursor="pointer"
              onClick={() => setSelectedUrl(url)}
            >
              View Full Size
            </Text>
          </Box>
        </Box>
      ))}
    </Box>
  )
}
