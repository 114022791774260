import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  approveCredential,
  rejectCredential,
} from '../../api/credentials/credential_actions'
import { CredentialCheckResponse } from '../../types/credentials'

export const useApproveCredentialMutation = ({
  onSuccess,
}: {
  onSuccess: () => void
}) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: approveCredential,
    onMutate: async (credentialId: number) => {
      await queryClient.cancelQueries({ queryKey: ['verifyCredentials'] })

      const previousData = queryClient.getQueryData<CredentialCheckResponse[]>([
        'verifyCredentials',
      ])

      queryClient.setQueryData<CredentialCheckResponse[]>(
        ['verifyCredentials'],
        (old) => {
          if (!old) return []

          return old
            .map((check) => ({
              ...check,
              checkCredentialAssocs: check.checkCredentialAssocs.filter(
                (assoc) => assoc.credential.id !== credentialId
              ),
            }))
            .filter((check) => check.checkCredentialAssocs.length > 0)
        }
      )

      return { previousData }
    },
    onError: (err, _, context) => {
      if (context?.previousData) {
        queryClient.setQueryData(['verifyCredentials'], context.previousData)
      }
    },
    onSuccess: () => {
      onSuccess()
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['verifyCredentials'] })
    },
  })
}

export const useRejectCredentialMutation = ({
  onSuccess,
}: {
  onSuccess: () => void
}) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: rejectCredential,
    onMutate: async (credentialId: number) => {
      await queryClient.cancelQueries({ queryKey: ['verifyCredentials'] })

      const previousData = queryClient.getQueryData<CredentialCheckResponse[]>([
        'verifyCredentials',
      ])

      queryClient.setQueryData<CredentialCheckResponse[]>(
        ['verifyCredentials'],
        (old) => {
          if (!old) return []

          return old
            .map((check) => ({
              ...check,
              checkCredentialAssocs: check.checkCredentialAssocs.filter(
                (assoc) => assoc.credential.id !== credentialId
              ),
            }))
            .filter((check) => check.checkCredentialAssocs.length > 0)
        }
      )

      return { previousData }
    },
    onError: (err, _, context) => {
      if (context?.previousData) {
        queryClient.setQueryData(['verifyCredentials'], context.previousData)
      }
    },
    onSuccess: () => {
      onSuccess()
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['verifyCredentials'] })
    },
  })
}
