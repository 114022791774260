import React, { createContext, useContext, useState, MouseEvent } from 'react'
import { Box, Modal, Text } from '@workwhile/ui'

type DocumentPreviewContextType = {
  setSelectedUrl: (url: string | null) => void
}

const DocumentPreviewContext = createContext<
  DocumentPreviewContextType | undefined
>(undefined)

export const useDocumentPreview = () => {
  const context = useContext(DocumentPreviewContext)
  if (!context) {
    throw new Error(
      'useDocumentPreview must be used within a DocumentPreviewProvider'
    )
  }
  return context
}

export const DocumentPreviewProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [selectedUrl, setSelectedUrl] = useState<string | null>(null)
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const [isDragging, setIsDragging] = useState(false)

  const handleMouseDown = (e: MouseEvent) => {
    setIsDragging(true)
    const startX = e.pageX - position.x
    const startY = e.pageY - position.y

    const handleMouseMove = (e: MouseEvent) => {
      setPosition({
        x: e.pageX - startX,
        y: e.pageY - startY,
      })
    }

    const handleMouseUp = () => {
      setIsDragging(false)
      document.removeEventListener('mousemove', handleMouseMove as any)
      document.removeEventListener('mouseup', handleMouseUp)
    }

    document.addEventListener('mousemove', handleMouseMove as any)
    document.addEventListener('mouseup', handleMouseUp)
  }

  return (
    <DocumentPreviewContext.Provider value={{ setSelectedUrl }}>
      {children}
      <Modal open={!!selectedUrl} onClose={() => setSelectedUrl(null)}>
        <Box
          position="fixed"
          top={position.y}
          left={position.x}
          backgroundColor="white"
          borderRadius="8px"
          boxShadow="0px 4px 12px rgba(0, 0, 0, 0.15)"
          zIndex={9999}
          width="80vw"
          maxWidth="900px"
          cursor={isDragging ? 'grabbing' : 'default'}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding="16px"
            borderBottom="1px solid"
            borderColor="gray.200"
            cursor="grab"
            onMouseDown={handleMouseDown}
            userSelect="none"
            backgroundColor="gray.50"
            borderTopRadius="8px"
          >
            <Text fontWeight="bold">Document Preview</Text>
            <Box
              cursor="pointer"
              onClick={() => setSelectedUrl(null)}
              padding="8px"
            >
              <Text fontSize="20px">×</Text>
            </Box>
          </Box>

          <Box
            padding="24px"
            display="flex"
            justifyContent="center"
            maxHeight="80vh"
            overflow="auto"
          >
            {selectedUrl ? (
              <img
                src={selectedUrl}
                style={{
                  maxWidth: '100%',
                  maxHeight: '75vh',
                  objectFit: 'contain',
                }}
                alt="Full size credential document"
                draggable={false}
              />
            ) : null}
          </Box>
        </Box>
      </Modal>
    </DocumentPreviewContext.Provider>
  )
}
