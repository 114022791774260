import React from 'react'
import { Card, Heading, Text, Box } from '@workwhile/ui'
import { CredentialCheckResponse } from '../../../types/credentials'
import { WorkerCard } from './WorkerCard'
import { DocumentPreview } from './DocumentPreview'

type ShiftCardProps = {
  check: CredentialCheckResponse
  workersNeeded?: number
  onAction: (workerId: number, action: 'approve' | 'reject') => void
}

export const ShiftCard: React.FC<ShiftCardProps> = ({
  check,
  workersNeeded,
  onAction,
}) => (
  <Card padding={['16px', '20px', '24px']} borderColor="primary">
    <Heading variant="h3" marginBottom="16px">
      {check.shift.position.name} - Shift {check.shift.id}
    </Heading>

    {workersNeeded != null && (
      <Text marginBottom="16px">Workers Needed: {workersNeeded}</Text>
    )}

    <Box>
      {(check.checkCredentialAssocs || []).map((assoc) => {
        if (!assoc || !assoc.worker) return null

        return (
          <Card
            key={assoc.worker.id}
            padding={['16px', '20px', '24px']}
            borderColor="primary100"
            marginBottom="16px"
          >
            <Box
              display="flex"
              flexDirection={['column', 'column', 'row']}
              gap={['16px', '16px', '24px']}
            >
              <WorkerCard
                worker={assoc.worker}
                credential={assoc.credential}
                onApprove={() => onAction(assoc.worker.id, 'approve')}
                onReject={() => onAction(assoc.worker.id, 'reject')}
              />
              <DocumentPreview urls={assoc.credential.urls} />
            </Box>
          </Card>
        )
      })}
    </Box>
  </Card>
)
