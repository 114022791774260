import React from 'react'
import { Input, Text, Box } from '@workwhile/ui'

type SearchBarProps = {
  searchTerm: string
  onSearchChange: (value: string) => void
  daysValue: string
  onDaysChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onDaysSubmit: () => void
}

export const SearchBar: React.FC<SearchBarProps> = ({
  searchTerm,
  onSearchChange,
  daysValue,
  onDaysChange,
  onDaysSubmit,
}) => (
  <Box display="flex" marginBottom="24px">
    <Box flex="1" marginRight="16px">
      <Text marginBottom="8px">Search</Text>
      <Input
        placeholder="Search by shift ID"
        value={searchTerm}
        onChange={(e) => onSearchChange(e.target.value)}
      />
    </Box>
    <Box width="300px">
      <Text marginBottom="8px">
        How many days would you like to look ahead?
      </Text>
      <Input
        type="number"
        min={1}
        max={30}
        value={daysValue}
        onChange={onDaysChange}
        onBlur={onDaysSubmit}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.currentTarget.blur()
          }
        }}
        placeholder="Days to look ahead"
      />
    </Box>
  </Box>
)
