import { Table } from '@workwhile/ui'
import React, { useState } from 'react'
import * as moment from 'moment-timezone'

import api from 'api'
import CollapsibleSection from 'components/Shared/CollapsibleSection'
import { User } from 'components/Shared/common_defs'
import StatusSetter from 'components/Shared/StatusSetter'
import { keyValueColumns } from 'components/Users/keyValueColumns'
import {
  BACKGROUND_CHECK_STATUSES,
  type BackgroundCheckStatus,
} from 'types/backgroundCheck'

interface Props {
  user: User
  refreshData: () => void
  defaultOpen: boolean
}

const STATUS_OPTIONS = BACKGROUND_CHECK_STATUSES.map((status) => {
  return { label: status, value: status }
})
const DATE_TIME_SUBMITTED_FORMAT = 'YYYY-MM-DD h:mma z'

const UserYardstikVerification = ({ user, refreshData }: Props) => {
  const timezone = user.address?.timezone || moment.tz.guess(true)

  const [newBasicStatus, setNewBasicStatus] =
    useState<BackgroundCheckStatus>('manually_clear') // input defaults to manually_clear
  const [submittingBasicStatus, setSubmittingBasicStatus] = useState(false)

  const [newMvrStatus, setNewMvrStatus] =
    useState<BackgroundCheckStatus>('manually_clear') // input defaults to manually_clear
  const [submittingMvrStatus, setSubmittingMvrStatus] = useState(false)

  const defaultOpen = false
  const basicStatus = undefined
  const basicSubmitted = undefined
  const mvrStatus = undefined
  const mvrSubmitted = undefined

  const basicReportID = '<missing>'
  const mvrReportID = '<missing>'

  const data = React.useMemo(
    () => [
      {
        key: 'basic_status',
        value: basicStatus || 'N/A',
        link: basicStatus
          ? `https://app.yardstik.com/report/${basicReportID}`
          : null,
      },
      {
        key: 'basic_submitted_at',
        value: basicSubmitted
          ? moment
              .tz(basicSubmitted, timezone)
              .format(DATE_TIME_SUBMITTED_FORMAT)
          : 'N/A',
      },
      {
        key: 'mvr_status',
        value: mvrStatus || 'N/A',
        link: mvrStatus
          ? `https://app.yardstik.com/report/${mvrReportID}`
          : null,
      },
      {
        key: 'mvr_submitted_at',
        value: mvrSubmitted
          ? moment.tz(mvrSubmitted, timezone).format(DATE_TIME_SUBMITTED_FORMAT)
          : 'N/A',
      },
    ],
    []
  )

  const submitBasicStatusChange = () => {
    setSubmittingBasicStatus(true)
    const r = window.prompt(
      `You are about to set USER(${user.id}) Yardstik Basic status to: ${newBasicStatus}. To execute this action, please press OK.`
    )
    if (r != null) {
      api
        .post(`/admin/user/${user.id}/yardstik`, {
          report_type: 'basic',
          status: newBasicStatus,
          reason: r,
        })
        .then((_response) => {
          alert(`Yardstik Basic status is now ${newBasicStatus}`)
          setSubmittingBasicStatus(false)
          refreshData()
        })
        .catch((error) => {
          console.log('ERROR OCCURED: ', error)
          setSubmittingBasicStatus(false)
        })
    } else {
      alert('Action Cancelled!')
      setSubmittingBasicStatus(false)
    }
    return
  }

  const submitMvrStatusChange = () => {
    setSubmittingMvrStatus(true)
    const r = window.prompt(
      `You are about to set USER(${user.id}) Yardstik MVR status to: ${newMvrStatus}. To execute this action, please press OK.`
    )
    if (r != null) {
      api
        .post(`/admin/user/${user.id}/yardstik`, {
          report_type: 'mvr',
          status: newMvrStatus,
          reason: r,
        })
        .then((_response) => {
          alert(`Yardstik MVR status is now ${newMvrStatus}`)
          setSubmittingMvrStatus(false)
          refreshData()
        })
        .catch((error) => {
          console.log('ERROR OCCURED: ', error)
          setSubmittingMvrStatus(false)
        })
    } else {
      alert('Action Cancelled!')
      setSubmittingMvrStatus(false)
    }
    return
  }

  return (
    <>
      <CollapsibleSection
        title="Yardstik Details (COMING SOON)"
        defaultOpen={defaultOpen}
      >
        <Table variant="borderless" data={data} columns={keyValueColumns} />
      </CollapsibleSection>
      <StatusSetter
        label="Set Yardstik Basic Status"
        disabled
        name="newBasicStatus"
        value={newBasicStatus}
        handleChange={(status) => setNewBasicStatus(status)}
        isLoading={submittingBasicStatus}
        submitStatusChange={submitBasicStatusChange}
        options={STATUS_OPTIONS}
        buttonType="confirm"
      />
      <StatusSetter
        label="Set Yardstik MVR Status"
        disabled
        name="newMvrStatus"
        value={newMvrStatus}
        handleChange={(status) => setNewMvrStatus(status)}
        isLoading={submittingMvrStatus}
        submitStatusChange={submitMvrStatusChange}
        options={STATUS_OPTIONS}
        buttonType="confirm"
      />
    </>
  )
}

export default UserYardstikVerification
