import React, { useState, useMemo } from 'react'
import { Box, Text } from '@workwhile/ui'
import { useVerifyCredentialsQuery } from '../../queries/credentials/useVerifyCredentialsQuery'
import { format } from 'date-fns'
import {
  useApproveCredentialMutation,
  useRejectCredentialMutation,
} from '../../queries/credentials/useVerifyCredentialsMutation'
import { useShiftDetails } from '../../queries/credentials/useShiftDetails'
import { Header } from './components/Header'
import { SearchBar } from './components/SearchBar'
import { ShiftCard } from './components/ShiftCard'
import { CredentialCheckResponse } from '../../types/credentials'
import { DocumentPreviewProvider } from './DocumentPreviewContext'
import { ConfirmationModal } from './components/ConfirmationModal'

type ShiftGroup = {
  date: string
  shifts: CredentialCheckResponse[]
}

const VerifyCredentials: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState('')
  const [inputValue, setInputValue] = useState('3')
  const [daysLookahead, setDaysLookahead] = useState(3)
  const [lastReviewTime, setLastReviewTime] = useState<Date>(new Date())
  const [modalState, setModalState] = useState<{
    isOpen: boolean
    workerId: number
    shiftId: number
    action: 'approve' | 'reject'
  }>({
    isOpen: false,
    workerId: 0,
    shiftId: 0,
    action: 'approve',
  })

  const {
    data: credentialChecks,
    isLoading,
    error,
  } = useVerifyCredentialsQuery(daysLookahead)

  const shiftDetails = useShiftDetails(credentialChecks)

  const approveMutation = useApproveCredentialMutation({
    onSuccess: () => {
      setModalState({
        isOpen: false,
        workerId: 0,
        shiftId: 0,
        action: 'approve',
      })
      setLastReviewTime(new Date())
    },
  })

  const rejectMutation = useRejectCredentialMutation({
    onSuccess: () => {
      setModalState({
        isOpen: false,
        workerId: 0,
        shiftId: 0,
        action: 'approve',
      })
      setLastReviewTime(new Date())
    },
  })

  const filteredShifts = useMemo((): ShiftGroup[] => {
    if (isLoading || !credentialChecks) return []

    const afterSearchFilter = credentialChecks.filter((check) =>
      check.shift.id.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )

    const afterAssocsFilter = afterSearchFilter.filter((check) => {
      return check.checkCredentialAssocs?.length > 0
    })

    const sorted = afterAssocsFilter.sort((a, b) => {
      const shiftA = shiftDetails[a.shift.id]
      const shiftB = shiftDetails[b.shift.id]

      if (!shiftA || !shiftB) return 0

      return (
        new Date(shiftA.startsAt).getTime() -
        new Date(shiftB.startsAt).getTime()
      )
    })

    return sorted.reduce((groups: ShiftGroup[], check) => {
      const shiftDetail = shiftDetails[check.shift.id]
      if (!shiftDetail) return groups

      const date = format(new Date(shiftDetail.startsAt), 'EEE, MMM d')

      const existingGroup = groups.find((group) => group.date === date)
      if (existingGroup) {
        existingGroup.shifts.push(check)
      } else {
        groups.push({ date, shifts: [check] })
      }

      return groups
    }, [])
  }, [credentialChecks, searchTerm, shiftDetails, isLoading])

  const handleAction = (workerId: number, action: 'approve' | 'reject') => {
    const shift = credentialChecks?.find((check) =>
      check.checkCredentialAssocs.some((assoc) => assoc.worker.id === workerId)
    )
    if (!shift) return

    setModalState({
      isOpen: true,
      workerId,
      shiftId: shift.shift.id,
      action,
    })
  }

  const handleDaysInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
  }

  const handleDaysInputSubmit = () => {
    const value = parseInt(inputValue)
    if (!isNaN(value)) {
      if (value < 1) {
        setDaysLookahead(1)
        setInputValue('1')
      } else if (value > 30) {
        setDaysLookahead(30)
        setInputValue('30')
      } else {
        setDaysLookahead(value)
      }
    } else {
      setDaysLookahead(3)
      setInputValue('3')
    }
  }

  const confirmAction = async () => {
    const { workerId, shiftId, action } = modalState
    const currentCheck = credentialChecks?.find(
      (check) => check.shift.id === shiftId
    )
    const workerAssoc = currentCheck?.checkCredentialAssocs.find(
      (assoc) => assoc.worker.id === workerId
    )

    if (!workerAssoc) {
      setModalState({ ...modalState, isOpen: false })
      return
    }

    const credentialId = workerAssoc.credential.id

    if (action === 'approve') {
      await approveMutation.mutateAsync(credentialId)
    } else {
      await rejectMutation.mutateAsync(credentialId)
    }
  }

  if (isLoading) return <Text padding="24px">Loading credentials...</Text>
  if (error)
    return (
      <Text padding="24px">
        Error loading credentials: {(error as Error).message}
      </Text>
    )
  if (!credentialChecks?.length)
    return <Text padding="24px">No credentials to verify at this time.</Text>

  return (
    <DocumentPreviewProvider>
      <Box padding="24px">
        <Header lastReviewTime={lastReviewTime} />

        <SearchBar
          searchTerm={searchTerm}
          onSearchChange={setSearchTerm}
          daysValue={inputValue}
          onDaysChange={handleDaysInputChange}
          onDaysSubmit={handleDaysInputSubmit}
        />

        <Box>
          {filteredShifts.length === 0 ? (
            <Text>No shifts pending certification review at this time.</Text>
          ) : (
            filteredShifts.map((group, groupIndex) => (
              <Box
                key={group.date}
                marginBottom={
                  groupIndex < filteredShifts.length - 1 ? '32px' : '0'
                }
              >
                <Text variant="h2" marginBottom="16px">
                  {group.date}
                </Text>

                {group.shifts.map((check) => (
                  <ShiftCard
                    key={check.shift.id}
                    check={check}
                    workersNeeded={shiftDetails[check.shift.id]?.workersNeeded}
                    onAction={handleAction}
                  />
                ))}
              </Box>
            ))
          )}
        </Box>

        <ConfirmationModal
          isOpen={modalState.isOpen}
          onClose={() => setModalState({ ...modalState, isOpen: false })}
          onConfirm={confirmAction}
          action={modalState.action}
          isProcessing={approveMutation.isPending || rejectMutation.isPending}
        />
      </Box>
    </DocumentPreviewProvider>
  )
}

export default VerifyCredentials
