import snakecaseKeys from 'snakecase-keys'
import camelCase from 'camelcase'

export function toSnakecaseKeys(obj: object, options?: { deep?: boolean }) {
  return snakecaseKeys(
    { ...obj },
    {
      deep: options?.deep ?? false,
    }
  )
}

export function toCamelcaseKeys(obj: any, options?: { deep?: boolean }): any {
  if (Array.isArray(obj)) {
    return obj.map((item) => toCamelcaseKeys(item, options))
  }

  if (obj !== null && typeof obj === 'object') {
    return Object.keys(obj).reduce(
      (acc, key) => {
        const camelKey = camelCase(key)
        const value = obj[key]

        acc[camelKey] = options?.deep ? toCamelcaseKeys(value, options) : value

        return acc
      },
      {} as Record<string, any>
    )
  }

  return obj
}
