export const BACKGROUND_CHECK_STATUSES = [
  'manually_clear',
  'pending',
  'consider',
  'clear',
  'suspended',
  'failed',
] as const

export type BackgroundCheckStatus = (typeof BACKGROUND_CHECK_STATUSES)[number]
