import {
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query'
import api from 'api'
import { Listing } from 'api/typings'

export type WorkerEligibility = {
  /** Total number of workers found within radius (miles) */
  total_market: number
  /** Total number of workers found within the current tier */
  total_tier: number
  /** From total, number of workers able to accept a listing */
  eligible_tier: number
  /** Aggregation of reasons across total which prevent workers from accepting a listing */
  ineligibility_reasons: Record<string, number>
  /** The listing radius used to determine the total_market value */
  listing_radius: number
}

export function useWorkerEligibility(
  listingId: Listing['id'] | string,
  /** Minimum and maximum range segments, in miles. Note: these values are required, but are ignored if the tier is not "all" */
  { min = 0, max = 5 }: { min: number; max: number },
): UseQueryResult<WorkerEligibility> {
  return useQuery({
    queryKey: ['listing', listingId, 'eligibility', { min, max }],
    queryFn: async () =>
      api
        .get(`/admin/listing/${listingId}/eligibility/${min}/${max}`)
        .then((result) => result.data),
    enabled: !!listingId,
    retry: false,
  })
}
