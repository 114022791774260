import React from 'react'
import { Heading, Text } from '@workwhile/ui'
import { formatInTimeZone } from 'date-fns-tz'

type HeaderProps = {
  lastReviewTime: Date
}

export const Header: React.FC<HeaderProps> = ({ lastReviewTime }) => (
  <>
    <Heading>Verify Certifications</Heading>
    <Text color="gray.500" marginBottom="24px">
      Last reviewed:{' '}
      {formatInTimeZone(
        lastReviewTime,
        Intl.DateTimeFormat().resolvedOptions().timeZone,
        'MMM d, yyyy h:mm a zzz'
      )}
    </Text>
  </>
)
