import React, { useState } from 'react'
import { Work, WorkShift } from '../../Shared/common_defs'
import Loading from '../../Shared/loading'
import { useShiftDetails } from 'queries/shifts'
import BootstrapTable from 'react-bootstrap-table-next'
import { Box, Button, Icon, Text } from '@workwhile/ui'
import WorkerDetail from '../Workers/WorkerDetail'
import { FILLED_STATUSES } from 'components/utils/util'
import { ChevronDown, ChevronUp } from 'lucide-react'

const DetailButton = ({ work, shift, fetchWorkStats, fetchWorkers }) => {
  const [show, setShow] = useState(false)

  return (
    <Box>
      <Button kind="small" variant={'text'} onClick={() => setShow(!show)}>
        Detail <Icon color="primary" icon={show ? ChevronUp : ChevronDown} />
      </Button>
      {show && (
        <WorkerDetail
          workId={work.id}
          workerId={work.worker.id}
          shift={shift}
          /** @ts-ignore-error we aren't using the arguments anyway */
          fetchWorkStats={fetchWorkStats}
          /** @ts-ignore-error we aren't using the arguments anyway */
          fetchWorkers={fetchWorkers}
        />
      )}{' '}
    </Box>
  )
}

const getBackgroundCheckStatus = (work: Work) => {
  let backgroundCheckStatusDisplay = ''
  if (work.worker.backgroundCheckStatus === 'pending') {
    backgroundCheckStatusDisplay = '[BGC_PENDING]'
  } else if (
    work.worker.backgroundCheckMvrStatus === 'pending' &&
    work.shift.position.isDrivingPosition
  ) {
    backgroundCheckStatusDisplay = '[MVR_PENDING]'
  }
  return backgroundCheckStatusDisplay
}

interface Props {
  shift: WorkShift
  fetchWorkStats: () => void
  open?: boolean
}

const WorkerList = ({ shift, fetchWorkStats }: Props) => {
  const workers = useShiftDetails(shift.id, 'work')

  if (workers.isLoading || workers.isFetching) {
    return <Loading type="button" />
  }

  if (!workers.data?.work?.length) {
    return <Text>Could not find workers for this shift.</Text>
  }

  return (
    <BootstrapTable
      condensed
      rowStyle={{verticalAlign: 'middle'}}
      bordered={false}
      keyField={'id'}
      data={workers.data?.work}
      columns={[
        {
          dataField: 'work.worker.name',
          text: 'Worker',
          formatter: (cell, row) => (
            <Text
              style={
                FILLED_STATUSES.includes(row.status)
                  ? { color: '#000000' }
                  : { color: 'rgba(0,0,0,0.5)' }
              }
            >{`${row.worker.name} (${row.worker.id})`}</Text>
          ),
        },
        {
          dataField: 'work.worker.status',
          text: 'Status',
          formatter: (cell, work) => {
            const statuses = [
              work.status,
              work.isReplaceable && '[REPLACEABLE]',
              work.replacedAt && '[REPLACED]',
              getBackgroundCheckStatus(work),
              work.confirmedAt !== 'N/A' && '*',
            ].filter(Boolean)
            return <Text>{statuses.join(' ')}</Text>
          },
        },
        {
          dataField: 'work.worker.id',
          text: '',
          formatter: (cell, row) => (
            <DetailButton
              shift={shift}
              work={row}
              fetchWorkStats={fetchWorkStats}
              fetchWorkers={workers.refetch}
            />
          ),
        },
      ]}
    />
  )
}

export default WorkerList
