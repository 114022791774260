import api from 'api'
import { toCamelcaseKeys } from 'lib/string'

export async function getCredentialsForVerification(daysLookahead: number) {
  const url = `/admin/credentials?days_lookahead=${daysLookahead}`

  const response = await api.get(url)

  const transformedData = response.data.map((item: any) => ({
    ...toCamelcaseKeys(item, { deep: true }),
    checkCredentialAssocs: item.check_credential_assocs.map((assoc: any) => ({
      ...toCamelcaseKeys(assoc, { deep: true }),
      credential: {
        ...toCamelcaseKeys(assoc.credential, { deep: false }),
        urls: assoc.credential.urls,
      },
    })),
  }))

  return transformedData
}

export function approveCredential(credentialId: number) {
  return api.post(
    `/admin/credentials/${credentialId}/approve`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
}

export function rejectCredential(credentialId: number) {
  return api.post(
    `/admin/credentials/${credentialId}/reject`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
}
